(function () {
  'use strict';

  /* @ngdoc object
   * @name wbOrder
   * @description
   *
   */
  angular
    .module('wbOrder', [
      'ui.router',
      'pascalprecht.translate',
      'wbForm',
      'wbCountry',
      'wbAuth',
      'wbNavigation',
      'ngSanitize',
      'angular.filter',
      'flow'
    ])
    .config(configFlowJS);

  function configFlowJS(flowFactoryProvider) {
    flowFactoryProvider.defaults = {
      withCredentials: true,
      singleFile: true,
      permanentErrors: [400, 401, 404, 500]
    };
  }
}());
